<template>
  <el-container class="schedule_detail m-t-60" v-if="!loading">
    <el-header class="my-header" style="font-size: 12px">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="4">
          <el-button type="text" class="header_back_button" icon="el-icon-arrow-left" @click="backPage()">Буцах
          </el-button>
        </el-col>
        <el-col :span="4">
          <span class="header_center_title">Хуваарь</span>
        </el-col>
        <el-col :span="4">
          <div class="flr">
            <span class="m-r-10" prob="first_name">{{user.name}}</span>
            <!-- <el-dropdown>
          <i class="el-icon-caret-bottom"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button @click="logout()">Гарах</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-row type="flex" class="row-bg m-b-100" justify="center">
      <el-col :span="24" class="home_body">
        <el-card shadow="always" :body-style="{ padding: '10px' }" style="border-radius:0px;line-height:2">
          <el-row v-if="pageSelectedSchedule.teacher">
            <el-col :span="8">
              <img :src="'https://image.dma.mn/' + pageSelectedSchedule.teacher.img_url" width="80px"
                class="teacher_image"><br />
            </el-col>
            <el-col :span="12" :offset="2 ">
              Багшийн нэр: {{pageSelectedSchedule.teacher.name}} <br />
              Холбоо барих: {{pageSelectedSchedule.teacher.phone}}
              <!-- {{ birthday.getDay() }} -->
              <!-- {{ birthday.getHours() }} :  {{ birthday.getMinutes() }} : {{ birthday.getSeconds() }} -->
            </el-col>
          </el-row>
          <div class="schedule_body_info m-t-10">
            <span class="title_center" style="color:gray">Дэлгэрэнгүй</span> <br />
            <!-- <span>Гараг:</span>{{pageSelectedSchedule.day}} өдөр <br/> -->
            <span>Ээлж:{{pageSelectedSchedule.eelj}}</span> <br />
            <span>Хичээл эхлэх цаг:</span>{{pageSelectedSchedule.start_time}} <br />
            <span>Хичээл дуусах цаг:</span>{{pageSelectedSchedule.end_time}} <br />
            <span v-if="pageSelectedSchedule.room">Хичээллэх анги:{{pageSelectedSchedule.room.room_name}} тоот</span> <br />
            <span>Сурагчдын тоо:</span>{{pageSelectedSchedule.students.length}} <br />
            <br />
            <div v-if='scheduleLogDetail.id !== null'>
              <el-button size="mini"
                v-if="birthday.getHours() >= pageSelectedSchedule.start_time.split(':')[0] && birthday.toISOString().split('T')[0] != scheduleLogDetail.created_at.split('T')[0] && scheduleLogDetail.student_attendances.length > 0"
                class="m-t-10 m-b-10 w-100" style="border-radius:10px" type="success"
                @click="createScheduleLogFunction()">
                Хичээлийг эхлүүлэх
              </el-button>
            </div>
            <div v-else>
              <el-button size="mini" class="m-t-10 m-b-10 w-100" style="border-radius:10px" type="success"
                @click="createScheduleLogFunction()">Хичээл эхлүүлэх</el-button>
            </div>
            <!-- <el-button size="mini" class="m-t-10 m-b-10 w-100" style="border-radius:10px" type="success"
              @click="createScheduleLogFunction()">Хичээл эхлүүлэх</el-button> -->
          </div>
          <div class="schedule_body_info_students m-t-20 m-b-20" v-if="!(pageSelectedSchedule.students.length > 0 && scheduleLogDetail.created_at.split('T')[0] == birthday.toISOString().split('T')[0])">
            <span><span class="m-l-25">С/Нэр</span></span>
              <el-card v-for="(defaultStudent, index) in pageSelectedSchedule.students" :key="index" shadow="hover" class="m-t-10 cursor-point">
                <div @dblclick="callUserInfoFunction(defaultStudent)">
                <el-row style="height:40px">
                  <el-col :span="20">
                    {{index+1 }}.
                    {{defaultStudent.name.slice(0, 2) + '.' + defaultStudent.last_name}}<br/>
                    <span class="student_detail_thin_txt" v-if="checkCountFunction(defaultStudent)">
                      Тайлбар:
                      {{ defaultStudent.student_note[0].description }}
                    </span>
                  </el-col>
                  </el-row>
                </div>
              </el-card>
            </div>
          <div v-loading="loading"
            v-if="pageSelectedSchedule.students.length > 0 && scheduleLogDetail.created_at.split('T')[0] == birthday.toISOString().split('T')[0]">
            <el-row type="flex" class="row-bg p-20 textcenter" justify="center">
              <el-col :span="18">
                {{ scheduleLogDetail.created_at.split('T')[0]}} <br />Өдрийн ирц
              </el-col>
            </el-row>
            <el-row :gutter="24" type="flex" class="row-bg" justify="space-between">
              <el-col :span="6">
                <div class="schedule_detail_count">
                  Ирсэн <br />
                  <span style="color:green"> {{returnStudentStatusCount(100)}}</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="schedule_detail_count">
                  Чөлөө <br />
                  <span style="color:orange">{{returnStudentStatusCount(102)}}</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="schedule_detail_count">
                  Тас <br />
                  <span style="color:orange">{{returnStudentStatusCount(101)}}</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="schedule_detail_count">
                  Өвчтэй <br />
                  <span style="color:#281C9D">{{returnStudentStatusCount(103)}}</span>
                </div>
              </el-col>
            </el-row>
            <div class="schedule_body_info_students m-t-20 m-b-20">
              <span><span class="m-l-25">С/Нэр</span><span class="m-l-160">Ирц</span></span>
              <el-card v-for="student, index in scheduleLogDetail.student_attendances" :key="index" class="m-t-10">
                <div @dblclick="callUserInfoFunction(student.student)">
                <el-row v-if="scheduleLogDetail.student_attendances.length > 0" style="height:40px">
                  <el-col :span="10">
                    {{index+1 }}.
                    {{student.student.name.slice(0, 2) + '.' + student.student.last_name}}
                  </el-col>
                  <template>
                    <el-col :span="14">
                      <el-button mini :disabled="student.status_id == 100"
                        :type="student.status_id == 100 ? 'success' : ''" size="mini"
                        @click="changeAttendanceValue(student.student.id, 100, student.id, student.percent)">и
                      </el-button>
                      <el-button mini :disabled="student.status_id == 102"
                        :type="student.status_id == 102 ? 'warning' : ''" size="mini"
                        @click="changeAttendanceValue(student.student.id, 102,student.id, student.percent)">ч
                      </el-button>
                      <el-button mini :disabled="student.status_id == 101"
                        :type="student.status_id == 101 ? 'danger' : ''" size="mini"
                        @click="changeAttendanceValue(student.student.id, 101,student.id, student.percent)">т
                      </el-button>
                      <el-button mini :disabled="student.status_id == 103"
                        :type="student.status_id == 103 ? 'primary' : ''" size="mini"
                        @click="changeAttendanceValue(student.student.id, 103,student.id, student.percent)">ө
                      </el-button>
                    </el-col>
                  </template>
                  <el-col :span="22" :offset="1" v-if="student.status_id === 100">
                    <el-slider
                      @change="changeAttendanceValue(student.student.id, student.status_id ,student.id, student.percent)"
                      v-model="student.percent" :step="10" show-stops>
                    </el-slider>
                  </el-col>
                  <el-col :span="22" :offset="1" v-if="student.status_id === 100"> <p style="color: grey;">Хоцролт</p>
                  </el-col>
                  <el-col :span="22" :offset="1" v-if="student.status_id === 100">
                    <el-slider
                      @change="changeLateMinuteValue(student.student.id, student.status_id ,student.id, student.late_minute)"
                      v-model="student.late_minute" :step="5" show-stops>
                    </el-slider>
                  </el-col>
                  <br />
                </el-row>
                </div>
              </el-card>
            </div>
          </div>
          <div v-else-if="pageSelectedSchedule.students.length === 0">
            <el-row type="flex" class="row-bg" justify="center">
              <el-col :span="24" class="p-10" style="text-align: center;">
                <el-image class="m-l-10" style="width: 70px; height: 70px" :src="emptyImg" fit="fill"></el-image>
                <h4 style="color:#d67f7f">Хичээлд хамрагдах сурагч хоосон байна.</h4>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <StudentDetailDialog v-if="this.$root.studentDetailDialogVariable" :selectedStudent="selectedStudentData"/>
  </el-container>
</template>

<script>
import services from '@/helpers/services.js'
import SrcPathImage from '@/assets/empty.png'
import StudentDetailDialog from '@/views/studentDetailDialog/studentDetailDialog.vue'
import {
  mapGetters
} from 'vuex'

export default {
  components: {
    StudentDetailDialog
  },
  computed: {
    ...mapGetters({
      selectedSchedule: 'getterScheduleDetail'
    })
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      birthday: new Date(),
      now: Date.now(),
      scheduleLogDetail: {
        id: null,
        created_at: '2222-01-01T10:12:50.5000z',
        student_attendances: [],
        lesson_name: ''
      },
      loading: true,
      emptyImg: SrcPathImage,
      selectedStudentData: {},
      pageSelectedSchedule: {}
    }
  },
  created () {
    this.getScheduleDetail()
    this.getScheduleById()
  },
  methods: {
    checkCountFunction (data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('student_note') === true) {
        if (data.student_note.length === 0) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    callUserInfoFunction (data) {
      this.selectedStudentData = data
      this.$root.studentDetailDialogVariable = true
    },
    backPage () {
      this.$router.push({
        path: '/'
      })
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    },
    getScheduleDetail () {},
    returnStudentStatusCount (status) {
      let count = 0
      this.scheduleLogDetail.student_attendances.forEach(element => {
        if (status === element.status_id) {
          count = count + 1
        }
      })
      return count
    },
    getScheduleById () {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: this.selectedSchedule.id
      }
      services.getScheduleById(payload)
        .then((response) => {
        //  this.getScheduleById
          this.pageSelectedSchedule = response.data
          this.$root.fullscreenLoading = false
          this.loading = false
          this.getScheduleLogFind()
        })
    },
    getScheduleLogFind () {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: this.selectedSchedule.id
      }
      services.getScheduleLogById(payload)
        .then((response) => {
          this.scheduleLogDetail = response.data
          this.$root.fullscreenLoading = false
          this.loading = false
        })
    },
    createScheduleLogFunction () {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: this.selectedSchedule.id,
        status_id: 100,
        day: this.birthday.getDay()
      }
      services.createScheduleLog(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.getScheduleLogFind()
            this.$root.fullscreenLoading = false
          }
          console.log(response)
        })
    },
    changeAttendanceValue (studentId, statusId, attendanceId, percent) {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_log_id: this.scheduleLogDetail.id,
        student_id: studentId,
        status_id: statusId,
        attendance_id: attendanceId,
        percent: statusId === 100 ? percent : null
      }
      services.createAttendance(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.getScheduleLogFind()
          }
          console.log(response)
        })
    },
    changeLateMinuteValue (studentId, statusId, attendanceId, lateMinute) {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_log_id: this.scheduleLogDetail.id,
        student_id: studentId,
        status_id: statusId,
        attendance_id: attendanceId,
        late_minute: statusId === 100 ? lateMinute : null
      }
      services.createAttendance(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.getScheduleLogFind()
          }
          console.log(response)
        })
    }
  }
}

</script>

<style>

</style>
